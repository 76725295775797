import './SelectionCard.css'
import { Footer } from '../Footer/Footer';
import { useEffect, useState } from 'react'
import './ReceivedCard.css'
import { Dialog } from 'primereact/dialog';

const all = [];
for(let i = 1; i < 16; i++){
    all.push(i)
}
let unused = JSON.parse(JSON.stringify(all));


export const SelectionCard = () => {
    const [showResult, setShowResult] = useState(false)

    const cardStorage = localStorage.getItem("card");
    let firstCard = false
    if(cardStorage !== null && cardStorage > 0){
        firstCard = Number.parseInt(localStorage.getItem("card"));
    }
    const [card, setCard] = useState();
    const [cardPos, setCardPos] = useState();
    

    const [modal, setModal] = useState(false);
    const [rotate, setRotate] = useState(true);
    const [rotate1, setRotate1] = useState(true);

    useEffect(() => {
        localStorage.setItem("card", cardPos);
    }, [cardPos])

    useEffect(() => {
        newCard(firstCard);
        setTimeout(()=>setRotate(false), 700)
        setTimeout(()=>setRotate1(false), 1300)
    }, [])

    const newCard = (card)=>{
        setRotate(true)
        setRotate1(true)

        if(unused.length === 0){
            unused = JSON.parse(JSON.stringify(all));
        }

        const first = card || Math.floor(Math.random() * unused.length)
        const value = JSON.parse(JSON.stringify(unused[first]))

        delete(unused[first]);
        setCardPos(first);
        unused = unused.filter(function (el) {
            return el != null;
        });

        setTimeout(()=>setCard(value), 600)
        setTimeout(()=>setRotate(false), 700)
        setTimeout(()=>setRotate1(false), 1300)
    }


    return (
        <>
            {!showResult ?
            <>
                <div className='main-block mb-5 md:mb-0'>
                    <div className="grid h-full">
                        <div className="col-12 md:col-6">
                            <div className="h-full flex flex-column justify-content-center">
                                <h1 className='main_title mb-0'>Метафорические карты</h1>
                                <p className='main-text mb-5'>ПСИХОЛОГИЧЕСКИЕ ПРИЧИНЫ ЗАЕДАНИЯ</p>
                                <div className="hidden md:block">
                                    <button className='btn-get_card' onClick={() => setShowResult(true)}>Получить карту</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 md:col-6">
                            <div className="h-full flex align-items-center justify-content-center md:justify-content-start">
                                <div className='cards flex relative' onClick={() => setShowResult(true)}>
                                    <img className='absolute' src="assets/images/bg-left_card.png" alt="" />
                                    <img className='right-card' src="assets/images/bg-right_card.png" alt="" />
                                    <img className='absolute center-card' src="assets/images/bg-center_card.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', position: 'absolute', left: 0 }} className=" block w-full md:hidden" >
                        <button className='btn-get_card' onClick={() => setShowResult(true)}>Получить карту</button>
                    </div>
                </div> 
                </>
                :
                <>
                    {
                        card && <>
                            <div className='main-block'>
                                <div className="grid h-full">
                                    <div className="col-12 md:col-6">
                                        <div className="h-full flex flex-column justify-content-center">
                                            <h1 className='main_title mb-0'>Метафорические карты</h1>
                                            <div className='hidden md:block'>
                                                <h3 className='question-title'>Задайте себе вопросы</h3>
                                                <div className='questions'>
                                                    <p>1. Кто изображен на этой карте? Что делает герой? Какие у него эмоции?</p>
                                                    <p>2. Что в целом происходит на этом изображении? Где могли бы происходить эти события? Наблюдала ли я это в реальной жизни?</p>
                                                    <p>3. Я есть на этом изображении? Почему моя роль именно такая? Какие эмоции у меня вызывает моя роль на карте? Какую роль я бы хотела?</p>
                                                    <p>4. Что происходило до изображаемых событий? Что будет происходить позже?</p>
                                                    <p>5. Какие эмоции у меня вызывает это изображение в целом? Когда я испытывала подобные эмоции? В чем их схожесть и различие?</p>
                                                    <p>6. Что мне нравится на этом изображении? Почему я замечаю именно это?</p>
                                                    <p>7. Что мне не нравится на этом изображении? Почему мне неприятно это видеть?</p>
                                                    <p>8. Как бы я могла изменить то, что изображено, чтобы картинка мне больше нравилась? Если представить, что это произошло, как меняются мои ощущения от увиденного?</p>
                                                    <p>9. Почему в ответ на мой запрос мне открылась именно эта карта?</p>
                                                    <p>10. Как эта карта отвечает на мой текущий запрос? Какие идеи из этой сессии я могу использовать для решения своего вопроса? Какие эмоции, связанные с этой сессией, будут полезны?</p>
                                                </div>
                                            </div>
                
                                            <div className='hidden md:flex mt-5'>
                                                <button disabled={rotate1} className='btn-enlarge_card px-2' onClick={() => { setModal(true) }}>Увеличить карту</button>
                                                <button disabled={rotate1} className='btn-next_card px-2' onClick={() => newCard()}>Следующая карта</button>
                                            </div>
                
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="h-full flex align-items-center justify-content-center md:justify-content-start">
                                            <div className='cards flex relative'>
                                                <img className='absolute' src="assets/images/bg-left_card.png" alt="" />
                                                <img className='right-card' src="assets/images/bg-right_card.png" alt="" />
                                                <div className='absolute center-card'>
                                                    <div className="relative" onClick={() => { setModal(true) }}>
                                                        {!rotate1 && <p className='number-card'>0{card < 10 ? 0 : ''}{card}</p>}
                                                        {!rotate1 && <p className='name-card'>Ваша карта*</p>}
                                                        <img className={(rotate ? 'card-rotate rotate' : 'card-rotate')} src={(`assets/images/${card}.jpg`)} alt="" />
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                
                                </div >
                
                                <div className='btns-block block md:hidden'>
                                    <button disabled={rotate1} className='btn-enlarge_card px-2' onClick={() => { setModal(true) }}>Увеличить карту</button>
                                    <button disabled={rotate1} className={'btn-next_card px-2'} onClick={() => newCard()}>Следующая карта</button>
                                </div>
                                <Dialog style={{width: '100%', height: '100%'}} header={'0' + (card < 10 ? 0 : '') + card} visible={modal} onHide={() => { setModal(false) }}>
                                    <div style={{width: '100%', height: '100%', backgroundImage: `url(assets/images/${card}.jpg)`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize:'contain'}} className={(rotate ? 'card-rotate rotate' : 'card-rotate')} alt="" />
                                </Dialog>
                            </div>
                
                            <div className='block md:hidden questions-block'>
                                <h3 className='question-title'>Задайте себе вопросы</h3>
                                <div className='questions'>
                                    <p>1. Кто изображен на этой карте? Что делает герой? Какие у него эмоции?</p>
                                    <p>2. Что в целом происходит на этом изображении? Где могли бы происходить эти события? Наблюдала ли я это в реальной жизни?</p>
                                    <p>3. Я есть на этом изображении? Почему моя роль именно такая? Какие эмоции у меня вызывает моя роль на карте? Какую роль я бы хотела?</p>
                                    <p>4. Что происходило до изображаемых событий? Что будет происходить позже?</p>
                                    <p>5. Почему в ответ на мой запрос мне открылась именно эта карта?</p>
                                    <p>6. Какие эмоции у меня вызывает это изображение в целом? Когда я испытывала подобные эмоции? В чем их схожесть и различие?</p>
                                    <p>7. Что мне нравится на этом изображении? Почему я замечаю именно это?</p>
                                    <p>8. Что мне не нравится на этом изображении? Почему мне неприятно это видеть?</p>
                                    <p>9. Как бы я могла изменить то, что изображено, чтобы картинка мне больше нравилась? Если представить, что это произошло, как меняются мои ощущения от увиденного?</p>
                                    <p>10. Как эта карта отвечает на мой текущий запрос? Какие идеи из этой сессии я могу использовать для решения своего вопроса? Какие эмоции, связанные с этой сессией, будут полезны?</p>
                                </div>
                            </div>
                            <div style={{opacity: 0}}>
                                {all.map(e=><div style={{width: '100%', height: '100%', backgroundImage: `url(assets/images/${card}.jpg)`, backgroundPosition: 'top center', backgroundRepeat: 'no-repeat', backgroundSize:'contain'}} className={(rotate ? 'card-rotate rotate' : 'card-rotate')} alt="" />)}
                            </div>
                        </>
                    }
                </>
                }
                <Footer visible={!showResult}/>

        </>
    )
}