import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css'
import { SelectionCard } from './SelectionCard/SelectionCard';
import { ReceivedCard } from './ReceivedCard/ReceivedCard';
import { Footer } from './Footer/Footer';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <SelectionCard />
    </div>
  )
}
export default App;
