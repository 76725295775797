import { useEffect } from 'react'
import { useState } from 'react'
import './Footer.css'

export const Footer = ({visible}) => {
    const [showPreparing, setShowPreparing] = useState(visible)
    const [showHowWork, setShowHowWork] = useState(visible)
    
    useEffect(() => {
        setShowPreparing(visible);
        setShowHowWork(visible);   
    }, [visible])

    return (
        <div className="Footer">
            <div className="grid">
                <div className="col-12 md:col-6 pl-0 py-0">
                    <div className='preparing-to_work flex flex-column py-2' onClick={() => setShowPreparing(!showPreparing)}>
                        <div className='preparing-title flex justify-content-between align-items-center'>
                            <p className='footer-title my-0'>Подготовка к работе с картами</p>
                            <div className='flex align-items-center'>
                                {showPreparing ? <img src="assets/images/minus.svg" alt="" /> :
                                    <img src="assets/images/plus.svg" alt="" />}
                            </div>
                        </div>

                        {showPreparing && <div className='footer-list mt-5 mb-2'>
                            <p> 1. Выделите себе <span>10-20 минут</span>  на работу, отложите все дела.</p>
                            <p>2. Подготовьте блокнот и ручку. Лучше использовать именно блокнот, чтобы записи не потерялись, и вы могли вернуться к ним позже. Решение не всегда приходит сразу, иногда важно, чтобы прошло время, прежде чем вы сможете пересмотреть свои записи и найти ответ.</p>
                            <p>3. Найдите уединенное место, отключите гаджеты и попросите близких, по возможности, не беспокоить вас во время сессии. В процессе работы с картами оень важно концентрироваться на деталях, и то, что вас отвлекает, может легко сбить с правильного направления мыслей.</p>
                            <p>4. Приготовьте себе чай или обычную воду, чтобы в случае жажды вы могли не отвлекаться.</p>
                            <p>5. Перед тем, как открывать карты, подумайте о своем текущем запросе на работу. Очень важно, чтобы вопос, который вы задаете, был прямым – избегайте формулировок формата «Мне сделать это или это…». Корректная формулировка вопроса: <span>«Что мне сделать, чтобы…» или «Что мне поможет/мешает достичь…».</span> </p>
                        </div>
                        }
                    </div>
                </div>

                <div className="col-12 md:col-6 pl-0 md:pl-5 py-0">
                    <div className='how_work flex flex-column py-2' onClick={() => setShowHowWork(!showHowWork)}>
                        <div className='preparing-title flex justify-content-between align-items-center'>
                            <p className='footer-title my-0'>Как правильно работать с картами </p>
                            <div className='flex align-items-center'>
                                {showHowWork ? <img src="assets/images/minus.svg" alt="" /> :
                                    <img src="assets/images/plus.svg" alt="" />}
                            </div>
                        </div>

                        {showHowWork && <div className='footer-list mt-5 mb-2'>
                            <p> 1. Начинайте сразу после открытия говорить, что вы видите на карте. Первые ассоциации ― самые важные.</p>
                            <p>2. Не пытайтесь “фильтровать” свои ассоциации, выбирая самые логичные или уместные из них. Абсолютно все ваши ассоциации правильные, здесь нет неверных ответов. Именно через эти не правильные на первый взгляд ассоциации вы придете к решению своего запроса.</p>
                            <p> 3. Старайтесь работать с той картой, которая открывается самой первой, даже если вам кажется, что она не подходит. Менять карту рекомендуется только в том случае, если вам открылась карта, с которой вы уже работали ранее, и вас сбивают ассоциации, связанные с прошлой сессией. Но даже в этом случае вы можете оставить карту, анализируя, в том числе, и ее связь с предыдущим запросом: «Почему именно эта карта открылась на оба запроса?», «О чем мне говорит одинаковый выбор карты», «Что общего между этими запросами» и тд.</p>
                            <p>4. В процессе работы с картой делайте записи тезисно – буквально несколько ключевых слов. Вернитесь к записям уже после того, как вы выполните всю работу, прописав, в частности, следующие пункты:</p>
                            <p><span>- Что было самым ценным в процессе сегодняшней работы?</span></p>
                            <p><span>- Какой вывод стал самым главным? Почему?</span></p>
                            <p><span>- Как я могу использовать полученные знания?</span></p>
                            <p> 5. Работая с картой, проходите по стандартному списку вопросов, но видоизменяйте и расширяйте его под свой запрос. Если чувствуете, что вам сложно отвечать, попробуйте отвлечься на нейтральные вопросы о самом изображении: «Какие цвета преобладаюня этот цвет?», «Что могло происходить до изображаемых событий? Что произойдет после?».</p>
                        </div>}


                    </div>
                </div>

            </div>
            <div className="col-12 md:col-6">
                <p className='warning'>* У каждой карты есть свой автор. Приведенные изображения использованы для демонстрационной работы. </p>
            </div>

        </div>

    )
}